import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import './knowledgeBankArticle.scss';
import { Header } from '../../components/common/Header';
import { knowledgeBankActions } from '../../redux/knowledgeBank/knowledgeBankSlice';
import {
  getArticlesSelector,
  getCurrentCategorySelector,
  getIsFetching,
} from '../../redux/knowledgeBank/knowledgeBankSelectors';
import { KnowledgeBankArticleCard, NavigationMenu } from '../../components';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import KbArticleCardHungary from '../../components/knowledgeBankArticleCard/KBArticleCardHungary/KBArticleCardHungary';
import { Routes } from '../../const';
import { ContentSpinner } from '../../components/common/Spinner/ContentSpinner';
import { selectCategoryBanners } from '../../redux/banners/bannersSelectors';
import { bannersActions } from '../../redux/banners/bannersSlice';
import { BannerCard } from '../../components/bannerCard';
import { isHungary } from '../../helpers';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { amplitudeKBArticleResolver } from '../../helpers/ampitudeHelper';
import { analyticHelper } from '../../helpers/analyticHelper';
import { surveyActions } from '../../redux/survey/surveySlice';
import { specialQuizActions } from '../../redux/specialQuizes/specialQuizesSlice';
import { multibrandQuizActions } from '../../redux/multibrandQuiz/multibrandQuizSlice';
import { IArticle } from '../KBBrands/KBBrandsTypes';

export const KnowledgeBankArticlesComponent = ({ match, location, history }: any) => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const selectedCategoryId = match.params.id;
  const selectedKBBrandId = match.params.brand_id;

  const isFetching = useSelector(getIsFetching);
  const articles = useSelector(getArticlesSelector) as IArticle[];
  const banners = useSelector(selectCategoryBanners);
  // @ts-ignore
  const category = useSelector((state) => getCurrentCategorySelector(state, selectedCategoryId));

  const isUpdatedCategory = isHungary() && category.should_choose_brand;

  useEffect(() => {
    dispatch(knowledgeBankActions.getArticles({ selectedCategoryId, selectedKBBrandId }));
    dispatch(bannersActions.getBannersForCategory({ categoryId: selectedCategoryId }));
  }, [dispatch, selectedCategoryId, selectedKBBrandId]);

  const openArticle = (article: IArticle) => {
    const { is_new, brand, url, special_quiz_id, survey_id, is_multibrand_quiz } = article;

    analyticReport(article);
    dispatch(knowledgeBankActions.setCurrentArticle(article));
    if (url) {
      dispatch(knowledgeBankActions.openArticle({ article }));
      analyticHelper.openArticle();
    } else if (special_quiz_id) {
      is_multibrand_quiz
        ? dispatch(multibrandQuizActions.startDefinedMultibrandQuiz({ id: special_quiz_id, is_new }))
        : dispatch(specialQuizActions.startSpecialQuiz({ special_quiz_id, brand_id: brand.id }));
    } else if (survey_id) {
      dispatch(surveyActions.startSurvey({ survey_id, surveyStatus: is_new }));
      analyticHelper.startSurvey(survey_id);
    } else {
      console.error('Open Article error: missing handler function');
    }
  };

  const goBackCallback = useCallback(() => {
    if (!isUpdatedCategory) {
      dispatch(knowledgeBankActions.resetArticles());
      history.push(Routes.KnowledgeBankRoute);
    } else {
      history.push(`${Routes.BrandInformation}/${selectedCategoryId}`);
    }
  }, [history, dispatch]);

  const analyticReport = (article: IArticle) => {
    amplitudeKBArticleResolver.hasOwnProperty(category.id) && amplitudeKBArticleResolver[category.id](article);
  };

  return (
    <div className="knowledge-bank-article-page">
      <ScrollBlock fromTop>
        <Header
          customClass={classnames({ ['knowledge-bank-article-page__header']: isUpdatedCategory })}
          hasBackButton
          goBackCallback={goBackCallback}
          buttonColor={isUpdatedCategory ? 'black' : 'white'}
        >
          <p
            className={
              isUpdatedCategory
                ? 'knowledge-bank-article-page__header-hungaryText'
                : 'knowledge-bank-article-page__header-text'
            }
          >
            {!isUpdatedCategory && category ? category.name : formatMessage('Choose the product')}
          </p>
        </Header>
        {isFetching ? (
          <ContentSpinner />
        ) : (
          <div
            className={classnames('knowledge-bank-article-page__content', {
              ['knowledge-bank-article-page__hungary-content']: isUpdatedCategory,
            })}
          >
            {banners.map((banner) => (
              <BannerCard key={`banner-${banner.id}`} banner={banner} />
            ))}

            {articles &&
              articles.length > 0 &&
              articles.map((article) =>
                !isUpdatedCategory ? (
                  <KnowledgeBankArticleCard
                    onClick={openArticle}
                    key={article.id}
                    article={article}
                    showBrand={!category?.should_choose_brand && article.brand}
                    showDate={category.show_published_at}
                  />
                ) : (
                  <KbArticleCardHungary article={article} key={article.id} onClick={openArticle} />
                ),
              )}
          </div>
        )}
        <NavigationMenu location={location} />
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};

export const KnowledgeBankArticles = withRouter(KnowledgeBankArticlesComponent);
