import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState, useEffect } from 'react';
import classnames from 'classnames';

import './providersCarousel.scss';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { checkIsIphoneX } from '../../helpers/checkIsIphoneX';
import { analyticHelper } from '../../helpers/analyticHelper';
import { bonusShopActions } from '../../redux/bonusShop/bonusShopSlice';
import { getSelectedProvider } from '../../redux/bonusShop/bonusShopSelectors';
import { modalActions } from '../containers/Modal/modalSlice';
import { BonusShopProductInfo } from '../bonusShopProductInfo';
import useFeatureToggles from '../../hooks/useFeatureToggles';
import PurchaseLimitWarning from './PurchaseLimitWarning';
import { Button } from '../common/Button';

const SLIDES_PER_VIEW = 1.5;

export const PhysicalProvidersCarousel = ({ providers, currentProviderIndex = 0 }) => {
  const { t } = useInternationalization();
  const dispatch = useDispatch();
  const selectedProvider = useSelector(getSelectedProvider);
  const selectedColorId = useSelector((state) => state.bonusShop.selectedColorId);
  const { isOrderDetailsEnabled } = useFeatureToggles();

  const [currentIndex, updateCurrentIndex] = useState(currentProviderIndex);
  const [disableOrder, setDisableOrder] = useState(0);

  const handleSlideChange = (swiper) => {
    updateCurrentIndex(swiper.activeIndex);
  };

  const showModalCallback = () => {
    dispatch(
      modalActions.openPromptModal({
        btnText: t('OK(choose color)'),
        title: t("Choose the gift's color"),
        description: t('Choose the color and tap for completing your order.'),
      }),
    );
  };

  useEffect(() => {
    const selectedProviderId = providers[currentIndex] && providers[currentIndex].id;
    dispatch(bonusShopActions.setSelectedProviderId({ selectedProviderId }));
    setDisableOrder(false);
    dispatch(bonusShopActions.setSelectedColorId({ selectedColorId: null }));
  }, [dispatch, providers, currentIndex]);

  const handleOrder = () => {
    setDisableOrder(true);
    if (!selectedColorId && selectedProvider.colors.length > 0) {
      showModalCallback();
      setDisableOrder(false);
      return;
    }

    analyticHelper.bonusOrder();

    if (isOrderDetailsEnabled) {
      return dispatch(
        bonusShopActions.checkAvailability({
          productId: selectedProvider.id,
        }),
      );
    }

    dispatch(
      bonusShopActions.makeOrder({
        productId: selectedProvider.id,
        product_color_id: selectedColorId,
      }),
    );
  };

  const handleOrderBtnClick = () => {
    dispatch(
      modalActions.openPromptModal({
        btnText: 'Order(Confirm)',
        title: t('Are you sure you want to order a product?'),
        description: "Please note that after confirmation you won't be able to cancel the order.",
        image: <img className="modal-item-image" src={selectedProvider.photo} alt="" />,
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
          setTimeout(handleOrder);
        },
      }),
    );
  };

  const getProductsByColor = (color) => {
    const backgroundColor = color.color;
    if (color.id === selectedColorId) {
      return (
        <div key={color.id} className="products__product-physical-container">
          <div
            className={classnames('products__product-color', 'selected-physical')}
            style={{ backgroundColor: `#${backgroundColor}` }}
          />
          <div className="products__product-color-name">{color.name}</div>
        </div>
      );
    } else {
      return (
        <div
          key={color.id}
          className="products__product-physical-container"
          onClick={() => dispatch(bonusShopActions.setSelectedColorId({ selectedColorId: color.id }))}
        >
          <div
            className={classnames('products__product-color', selectedColorId && 'not-selected')}
            style={{ backgroundColor: `#${backgroundColor}` }}
          />
          <div className="products__product-color-name">{color.name}</div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="carousel-block">
        <Swiper slidesPerView={SLIDES_PER_VIEW} onSlideChange={handleSlideChange} spaceBetween={-10}>
          {providers.map((provider) => (
            <SwiperSlide
              className={classnames('carousel-block--swiper-container', {
                'carousel-last-item': currentIndex === providers.length - 1,
              })}
            >
              <img className="item-image" src={provider.photo} alt={provider.name} key={provider.id} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="products">
          {selectedProvider && selectedProvider.colors && selectedProvider.colors.length > 0 ? (
            <div className="products-container">
              {selectedProvider.colors.map((product) => getProductsByColor(product))}
            </div>
          ) : (
            <div className="products-container-no-products-info" />
          )}
        </div>

        <BonusShopProductInfo
          description={selectedProvider?.description}
          price={selectedProvider?.price}
          promo_price={selectedProvider?.promo_price}
          product_name={selectedProvider?.name}
          discount_price_data={selectedProvider?.discount_price_data}
          count={selectedProvider?.count}
        />
      </div>
      <div
        className="product-info-block__button-container"
        style={{ marginBottom: checkIsIphoneX() ? '60px' : '30px' }}
      >
        <PurchaseLimitWarning className="purchase-limit-warning" />
        <Button
          disabled={disableOrder}
          customClass={`product-info-block__button${disableOrder ? '-disabled' : ''}`}
          onClick={() => handleOrderBtnClick()}
        >
          {t('Order')}
        </Button>
      </div>
    </>
  );
};
