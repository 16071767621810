import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { ContentSpinner } from '../../components/common/Spinner/ContentSpinner';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { Header } from '../../components/common/Header';
import { NavigationMenu } from '../../components';
import { KBBrands } from './KBBrands';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { knowledgeBankActions } from '../../redux/knowledgeBank/knowledgeBankSlice';
import { getIsFetching, getKBBrandsSelector } from '../../redux/knowledgeBank/knowledgeBankSelectors';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';
import { amplitudeHelper } from '../../helpers/ampitudeHelper';
import { APP_TYPES } from '../../const/appConstants';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../../const/heightConsts';

import './KBBrands.scss';
import KBBrandsTabaterra from './KBBrandsTabaterra/KBBrandsTabaterra';

export const MainKBBrands = ({ match, location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useInternationalization();
  const selectedCategoryId = match.params.id;
  const isFetching = useSelector(getIsFetching);

  const brands = useSelector(getKBBrandsSelector);

  useEffect(() => {
    dispatch(knowledgeBankActions.getKBBrands());
  }, [dispatch]);

  const selectKBBrand = (selectedKBBrand) => {
    amplitudeHelper.geninfoChoose(selectedKBBrand);
    dispatch(knowledgeBankActions.chooseKBBrand({ selectedKBBrand, selectedCategoryId }));
  };

  const TakeKBBrands = () => {
    switch (process.env.REACT_APP_APP_TYPE) {
      case APP_TYPES.TABATERRA_AZ: {
        return <KBBrandsTabaterra brands={brands} selectKBBrand={selectKBBrand} />;
      }
      default: {
        return <KBBrands brands={brands} selectKBBrand={selectKBBrand} />;
      }
    }
  };

  return (
    <div className="KB-choose-brand">
      <ScrollBlock fromTop>
        <Header goBackCallback={history.goBack} hasBackButton buttonColor="white">
          <p className="KB-choose-brand__header-text">{formatMessage('Choose the brand')}</p>
        </Header>
        {isFetching ? (
          <ContentSpinner />
        ) : (
          <div
            className="KB-choose-brand__content"
            style={{
              minHeight: isMobileSafari()
                ? `calc(100% - ${FOOTER_HEIGHT} - ${FOOTER_HEIGHT} - ${HEADER_HEIGHT})`
                : 'unset',
            }}
          >
            {TakeKBBrands()}
          </div>
        )}
        <NavigationMenu location={location} />
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};
