import { IArticle, statusType } from '../../../pages/KBBrands/KBBrandsTypes';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import styles from './KBArticleCardHungary.module.scss';

type KbArticleCardHungaryProps = {
  article: IArticle;
  onClick: (article: IArticle) => void;
};

const KbArticleCardHungary = ({ article, onClick }: KbArticleCardHungaryProps) => {
  const { formatMessage } = useInternationalization();
  const { product_status, preview_image } = article;

  const statusMap: statusType = {
    0: { color: '', text: '' },
    1: { color: '#01C1B6', text: 'New product' },
    2: { color: '#2A7BE6', text: 'Update' },
    3: { color: '#6B1893', text: 'Change name' },
  };

  const articleStatus = statusMap[product_status];

  return (
    <div className={styles.container} onClick={() => onClick(article)}>
      <div className={styles.imageWrapper}>
        <img className={styles.image} src={preview_image} alt="img" />
        <span style={{ backgroundColor: articleStatus?.color }} className={styles.statusInfo}>
          {articleStatus?.text && formatMessage(`${articleStatus.text}`)}
        </span>
      </div>
      <span className={styles.text}>{article.name}</span>
    </div>
  );
};

export default KbArticleCardHungary;
