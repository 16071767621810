import { Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import { mainPageActions } from '../../redux/mainPage/mainPageSlice';
import { getMainScreenData, getMainScreenIsFetching } from '../../redux/mainPage/mainPageSelectors';
import { SECTION_TYPES } from '../../const/mainScreenConsts';
import { analyticHelper } from '../../helpers/analyticHelper';
import { isBelarus } from '../../helpers';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { getUserInfoSelector } from '../../redux/profile/profileSelectors';
import { chanceActions } from '../../redux/chance/chanceSlice';
import { CONSENT_STATUSES } from '../../components/NewTermsSteps/termsConstants';

import { BoxComponent } from './boxComponent/boxComponent';
import { FocusComponent } from './focusComponent/focusComponent';
import { IconicItemComponent } from './itemComponent/iconicItemComponent';
import { ModuleComponent } from './moduleComponent/moduleComponent';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { NavigationMenu } from '../../components';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import NewTermsSteps from '../../components/NewTermsSteps/NewTermsSteps';
import FadingComponent from './Fading/FadingComponent';
import { LoaderContainer } from '../../components/common';
import ItemComponentContainer from './itemComponent/mainScreenItemComponent/container/ItemComponentContainer';
import { NewUserComponent } from './userComponent/NewUserComponent/NewUserComponent';
import { isFeatureEnabled, CONFIG_KEYS } from '../../config';
import Snowfall from '../../components/Snowflakes';
import GoalTracker from '../../components/GoalTracker';

import './mainPage.scss';

export const MainPage = (props) => {
  const { history, location } = props;
  const data = useSelector(getMainScreenData);
  const { formatMessage } = useInternationalization();
  const { consent_processing_data, coins, monthly_earned, name } = useSelector(getUserInfoSelector);
  const dispatch = useDispatch();
  const isFetching = useSelector(getMainScreenIsFetching);

  useEffect(() => {
    dispatch(mainPageActions.getMainScreenData());
    dispatch(chanceActions.getChance());
    analyticHelper.onMainScreenOpenPage();
  }, [dispatch]);

  const onComponentClick = useCallback(
    (component) => {
      dispatch(mainPageActions.setCurrentComponent({ componentData: component }));
    },
    [dispatch],
  );

  const renderBlock = (block, key) => {
    return (
      <Fragment key={key}>
        {block.components.map((component, index) => {
          switch (block.type) {
            case SECTION_TYPES.BOX:
              return (
                <div key={index} onClick={() => onComponentClick(component)}>
                  <BoxComponent {...component} history={history} />
                </div>
              );
            case SECTION_TYPES.FOCUS:
              return (
                <div key={index} onClick={() => onComponentClick(component)}>
                  <FocusComponent {...component} history={history} />
                </div>
              );
            case SECTION_TYPES.ITEM:
              return (
                <div key={index} className="item-container" onClick={() => onComponentClick(component)}>
                  <IconicItemComponent {...component} history={history} />
                </div>
              );
            case SECTION_TYPES.MODULE:
              return (
                <div key={index} className="module-container" onClick={() => onComponentClick(component)}>
                  <ModuleComponent {...component} history={history} />
                </div>
              );
            case SECTION_TYPES.FADING_BLOCK:
              return (
                <div key={index} onClick={() => onComponentClick(component)}>
                  <FadingComponent {...component} />
                </div>
              );
            default:
              console.log(`Unsupported block type: ${block.type}`);
              break;
          }
          return <></>;
        })}
      </Fragment>
    );
  };

  if (!data) {
    return null;
  }

  return (
    <>
      <LoaderContainer isLoading={isFetching}>
        {consent_processing_data === CONSENT_STATUSES.ASK_CONSENT && isBelarus() ? (
          <NewTermsSteps />
        ) : (
          <div className="main-page">
            {isFeatureEnabled(CONFIG_KEYS.FALLING_SNOWFLAKES_ENABLED) && <Snowfall />}
            <ScrollBlock fromTop>
              <NewUserComponent
                username={data.user.username}
                userPhoto={data.user.photo}
                coins={coins}
                monthlyEarned={monthly_earned}
                name={name}
              />
              {isFeatureEnabled(CONFIG_KEYS.SHOW_GOAL_PROGRESS_ENABLED) && <GoalTracker />}
              <div className="main-page__blocks">
                {data.screen.sections.map((item, index) => {
                  if (item.type === SECTION_TYPES.ITEM) {
                    return (
                      <div key={index}>
                        <div className="main-page__items-headline">{formatMessage('The updates')}</div>
                        <ItemComponentContainer {...item} />
                      </div>
                    );
                  } else if (item.type === SECTION_TYPES.MODULE) {
                    return (
                      <div key={index}>
                        <div className="main-page__items-headline">{formatMessage('Gamified modules')}</div>
                        <div className="main-page__modules-block">{renderBlock(item)}</div>
                      </div>
                    );
                  } else if (item.type === SECTION_TYPES.FOCUS) {
                    return (
                      <div key={index}>
                        <div className="main-page__focus-block">{renderBlock(item)}</div>
                      </div>
                    );
                  } else {
                    return renderBlock(item);
                  }
                })}
              </div>
              <DisclaimerFooter />
              <NavigationMenu location={location} />
            </ScrollBlock>
          </div>
        )}
      </LoaderContainer>
    </>
  );
};

export const Main = withRouter(MainPage);
