import { getCoinsCount } from '../../helpers/coinFormatter';
import { getWordsFormat } from '../../helpers/getWordsFormat';
import { useInternationalization } from '../../hooks/useTranslationHook';
import saleIcon from '../../images/saleIcon.png';
import dangerItemsAmount from '../../images/dangerItemsAmount.svg';
import { isFeatureEnabled, CONFIG_KEYS } from '../../config';

import './bonusShopProductInfo.scss';

export const BonusShopProductInfo = ({ description, price, promo_price, product_name, discount_price_data, count }) => {
  const { formatMessage, t } = useInternationalization();
  const { discount_price, name, color, discount } = discount_price_data || {};
  const discountPrice = discount_price >= 0;
  const priceWrapperClasses = promo_price && { flexDirection: 'column' };

  return (
    <div className="product-info-block">
      <div className={`product-info-block__price${promo_price || discountPrice ? '-promo' : ''}`}>
        {discountPrice && (
          <div>
            <span className="product-info-block__non-promo-price">
              {price} {formatMessage(getWordsFormat(discount_price, 'points'))}
            </span>
          </div>
        )}
        <div className="product-info-block__price-wrapper" style={priceWrapperClasses}>
          {promo_price && (
            <div className="product-info-block__non-promo-price">
              {price} {formatMessage(getWordsFormat(price, 'points'))}
            </div>
          )}
          {!discountPrice ? getCoinsCount(promo_price || price) : discount_price}{' '}
          {formatMessage(getWordsFormat(promo_price || price, 'points'))}
          {discount && (
            <div className="product-info-block__sale" style={{ backgroundColor: color }}>
              <img className="product-info-block__saleIcon" src={saleIcon} alt="sale" />
              <span>{`-${discount}% (${name?.toLowerCase()})`}</span>
            </div>
          )}
        </div>
      </div>
      {product_name && <p className="product-info-block__product_name">{product_name}</p>}
      <p className="product-info-block__description">{description}</p>
      {isFeatureEnabled(CONFIG_KEYS.SHOW_REMAINS_PHYSICAL_GOODS_ENABLED) && (
        <div className="product-info-block__remains_amount_container">
          <p className="product-info-block__remains_amount">
            <img src={dangerItemsAmount} alt="danger_items_amount" />
            <span className="product-info-block__remains_amount_count">{t('physical_goods.remains', { count })}</span>
          </p>
        </div>
      )}
    </div>
  );
};
