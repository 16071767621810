import { useDispatch, useSelector } from 'react-redux';
import { Routes } from '../../../const';

import { questionOfTheDaySliceActions } from '../../../redux/questionOfTheDay/questionOfTheDaySlice';
import { knowledgeBankActions } from '../../../redux/knowledgeBank/knowledgeBankSlice';
import { specialQuizActions } from '../../../redux/specialQuizes/specialQuizesSlice';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { COMPONENT_TYPES } from '../../../const/mainScreenConsts';
import { amplitudeHelper } from '../../../helpers/ampitudeHelper';

import './focusComponent.scss';
import { analyticHelper, MAIN_SCREEN_ACTIVITY_LABELS } from '../../../helpers/analyticHelper';
import { settingsActions } from '../../../redux/settings/settingsSlice';
import { GAME_MODES } from '../../../redux/settings/settingsConstants';
import { eventModeActions } from '../../../redux/eventMode/eventModeSlice';
import { singleModeActions } from '../../../redux/singleMode/singleModeSlice';
import { multibrandQuizActions } from '../../../redux/multibrandQuiz/multibrandQuizSlice';
import { surveyActions } from '../../../redux/survey/surveySlice';
import { selectChances } from '../../../redux/chance/chanceSelectors';

export const FocusComponent = (props) => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const { history, payload, image, type } = props;

  const chances = useSelector(selectChances);

  const handleClick = () => {
    amplitudeHelper.mainFocus(type);
    switch (type) {
      case COMPONENT_TYPES.SOLO_QUIZ:
        dispatch(settingsActions.setGameMode(GAME_MODES.SOLO));
        if (!payload.brand_id) {
          history.push(Routes.BrandsRoute);
          analyticHelper.onMainScreenFocusClick(MAIN_SCREEN_ACTIVITY_LABELS.SOLO);
        } else {
          history.push(`/solo-mode/${payload.brand_id}`);
          analyticHelper.onMainScreenFocusClick(MAIN_SCREEN_ACTIVITY_LABELS.DIRECTIONAL_SOLO, payload.brand_id);
        }
        return;
      case COMPONENT_TYPES.QUIZ_WITH_USER:
        dispatch(settingsActions.setGameMode(GAME_MODES.HEAD_TO_HEAD));
        if (!payload.brand_id) {
          history.push(Routes.BrandsRoute);
          analyticHelper.onMainScreenFocusClick(MAIN_SCREEN_ACTIVITY_LABELS.H2H);
        } else {
          history.push(`/quiz/${payload.brand_id}`);
          analyticHelper.onMainScreenFocusClick(MAIN_SCREEN_ACTIVITY_LABELS.DIRECTIONAL_H2H, payload.brand_id);
        }
        return;
      case COMPONENT_TYPES.CHECK_IN:
        history.push(Routes.CheckInStatusRoute);
        analyticHelper.onMainScreenFocusClick(MAIN_SCREEN_ACTIVITY_LABELS.CHECK_IN);
        return;
      case COMPONENT_TYPES.QUESTION_OF_THE_DAY:
        const { question, is_new, coins } = payload;
        dispatch(
          questionOfTheDaySliceActions.setQuestions({
            question,
            is_new,
            coins,
          }),
        );
        dispatch(questionOfTheDaySliceActions.startQuestionOfTheDay());
        analyticHelper.onMainScreenFocusClick(MAIN_SCREEN_ACTIVITY_LABELS.QOD);
        return;
      case COMPONENT_TYPES.KB_ARTICLE:
        dispatch(knowledgeBankActions.openArticle({ article: payload }));
        analyticHelper.onMainScreenFocusClick(MAIN_SCREEN_ACTIVITY_LABELS.KB_ARTICLE);
        return;
      case COMPONENT_TYPES.SPECIAL_QUIZ:
        dispatch(
          specialQuizActions.startSpecialQuiz({
            special_quiz_id: payload.id,
            brand_id: payload.brand_id,
          }),
        );
        analyticHelper.onMainScreenFocusClick(MAIN_SCREEN_ACTIVITY_LABELS.SPECIAL_QUIZ);
        return;
      case COMPONENT_TYPES.KB_CATEGORY:
        history.push(`/knowledge-bank/${payload.id}`);
        analyticHelper.onMainScreenFocusClick(MAIN_SCREEN_ACTIVITY_LABELS.KB_CATEGORY);
        return;
      case COMPONENT_TYPES.KB_GAME:
        dispatch(knowledgeBankActions.openArticle({ article: payload }));
        analyticHelper.onMainScreenFocusClick(MAIN_SCREEN_ACTIVITY_LABELS.KB_GAME);
        return;
      case COMPONENT_TYPES.SINGLE_MODE:
        if (payload.is_new) {
          dispatch(singleModeActions.setSingleModeStarted());
          history.push(Routes.SingleMode);
        } else {
          dispatch(
            modalActions.openPromptModal({
              title: formatMessage('Single mode is completed'),
              description: formatMessage('New quizzes will be available next wednesday'),
              btnText: formatMessage('Got it'),
              onButtonClick: () => {
                dispatch(modalActions.closeModal());
              },
            }),
          );
        }
        return;
      case COMPONENT_TYPES.EVENT_MODE:
        if (payload.is_new) {
          dispatch(eventModeActions.startEventMode());
        } else {
          dispatch(
            modalActions.openPromptModal({
              title: formatMessage('Good things come to those who wait'),
              description: formatMessage('You can play with a new quiz tomorrow!'),
              btnText: formatMessage('Got it'),
              onButtonClick: () => {
                dispatch(modalActions.closeModal());
              },
            }),
          );
        }
        return;
      case COMPONENT_TYPES.MULTIBRAND_QUIZ: {
        const { id, is_new } = payload;
        dispatch(multibrandQuizActions.startDefinedMultibrandQuiz({ id, is_new }));
        analyticHelper.onMainScreenFocusClick(MAIN_SCREEN_ACTIVITY_LABELS.MB_QUIZ);
        return;
      }
      case COMPONENT_TYPES.SURVEY: {
        const { survey_id } = payload;
        dispatch(surveyActions.startCertainSurvey({ survey_id }));
        analyticHelper.onMainScreenFocusClick(MAIN_SCREEN_ACTIVITY_LABELS.MB_QUIZ);
        return;
      }
      case COMPONENT_TYPES.LOYALTY_PROGRAM: {
        const { is_new } = payload;
        if (is_new) {
          history.push(Routes.LoyaltyProgramRoute);
        } else {
          dispatch(
            modalActions.openPromptModal({
              title: formatMessage('VIP Program is now finished'),
              description: formatMessage('We look forward to welcoming you back to the VIP Program again next time!'),
              btnText: formatMessage('Got it'),
              onButtonClick: () => {
                dispatch(modalActions.closeModal());
              },
            }),
          );
        }
        return;
      }
      case COMPONENT_TYPES.INVITE_CODE: {
        history.push(Routes.InviteCodeRoute);
        analyticHelper.mainscreenInviteCodeOpen();
        return;
      }
      case COMPONENT_TYPES.ACHIEVEMENTS: {
        history.push(Routes.Achievements);
        analyticHelper.mainscreenAchievementsOpen();
        return;
      }
      case COMPONENT_TYPES.ACHIEVEMENT: {
        const { achievement_id } = payload;
        history.push(`${Routes.SelectedAchievement}/${achievement_id}`);
        return;
      }
      case COMPONENT_TYPES.LEP: {
        history.push(Routes.LEP);
        return;
      }
      case COMPONENT_TYPES.LOTTERY: {
        if (chances.length > 1) {
          history.push(Routes.ChanceList);
        } else {
          const chanceId = chances[0].id;
          history.push(`${Routes.ChanceRoute}/${chanceId}`);
        }
        return;
      }
      case COMPONENT_TYPES.CUSTOM_ROUTE:
        history.push(payload.route);
        return;
      default:
        break;
    }
  };

  return (
    <div className="focus-block" onClick={handleClick}>
      <img className="focus-block__focus-photo" alt="focus-item" src={image} />
    </div>
  );
};
