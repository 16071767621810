import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useState } from 'react';

import styles from './KBBrandsTabaterra.module.scss';
import { knowledgeBankActions } from '../../../redux/knowledgeBank/knowledgeBankSlice';
import { LoadingPage } from '../../../components/common/Spinner/LoadingPage';
import { knowledgeBankApi } from '../../../api/knowledgeBankApi';
import { IBrand } from '../KBBrandsTypes';

interface KBBrandsTabaterraProps {
  brands: IBrand[];
  selectKBBrand: (brandId: number) => void;
}

const KBBrandsTabaterra = ({ brands, selectKBBrand }: KBBrandsTabaterraProps) => {
  const dispatch = useDispatch();
  const { id: selectedCategoryId } = useParams<{ id: string }>();

  const [isLoading, setIsLoading] = useState(false);

  const handleBrandSelect = async (brandId: number) => {
    try {
      setIsLoading(true);

      const { data } = await knowledgeBankApi.getArticles({ selectedCategoryId, selectedKBBrandId: brandId });

      if (data?.length === 1) {
        const article = data[0];

        if (article.url) {
          dispatch(knowledgeBankActions.openArticle({ article }));
          return;
        }
      }

      selectKBBrand(brandId);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className={styles.container}>
      {brands?.length &&
        brands.map(({ id, icon }) => (
          <div key={id} className={styles.brand} onClick={() => handleBrandSelect(id)}>
            <img src={icon} />
          </div>
        ))}
    </div>
  );
};

export default KBBrandsTabaterra;
