import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RefObject } from 'react';

import { ITask, TaskTypes } from '../../../../redux/challenge/challengeTypes';
import {
  isBonusShopTypeTask,
  isBrandQuizTypeTask,
  isBrandTypeTask,
  isCustomTextTypeTask,
  isGameTypeTask,
  isKBGamesWonNumberTask,
  isKnowledgeBankTypeTask,
  isLinkToChatTypeTask,
  isLotteryTypeTask,
  isProfileTypeTask,
  isQuestionOfTheDayTypeTask,
  isQuickTipsTypeTask,
  IsSalesTipsTypeTask,
  isSingleModeTypeTask,
  isSpecialQuizTypeTask,
} from '../../../../helpers/checkTaskType';

import { Routes } from '../../../../const';
import { singleModeActions } from '../../../../redux/singleMode/singleModeSlice';
import { modalActions } from '../../../../components/containers/Modal/modalSlice';
import { TaskCard } from '../../components';
import { challengeActions } from '../../../../redux/challenge/challengeSlice';
import { analyticHelper } from '../../../../helpers/analyticHelper';
import { s3FilePaths } from '../../../../const/appConstants';
import { selectChances } from '../../../../redux/chance/chanceSelectors';
import { compressImage } from '../../../../helpers/resizeImage';
import { isFeatureEnabled, CONFIG_KEYS } from '../../../../config';
import { knowledgeBankActions } from '../../../../redux/knowledgeBank/knowledgeBankSlice';

type Props = {
  task: ITask;
  photoFrame?: string;
  challengeId?: number;
  attachmentsButtonEnabled: boolean;
};

export const Task = ({ task, photoFrame, challengeId, attachmentsButtonEnabled }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const chances = useSelector(selectChances);
  const chanceId = chances[0]?.id;

  const { type, description, category_id, entity_id } = task;

  const handleCustomImageTypeTask = async (inputRef: RefObject<HTMLInputElement>) => {
    if (photoFrame) {
      history.push(Routes.DigitalFramePhoto, { taskId: task.id });
      return;
    }

    inputRef.current?.click();
    const files = inputRef && inputRef.current && inputRef.current.files;
    const file = files && files[0];
    if (!file || !file.type.match(/image.*/)) return;
    const formData = new FormData();
    let uploadedFile = file;

    if (isFeatureEnabled(CONFIG_KEYS.REDUCE_IMAGE_SIZE_ENABLED)) {
      uploadedFile = await compressImage(file);
    }
    formData.append('file', uploadedFile, uploadedFile.name);
    formData.append('file_path', s3FilePaths.challenge);
    dispatch(
      challengeActions.uploadChallengePhoto({
        formData: formData,
        id: challengeId,
        taskId: task.id,
        taskType: 'CUSTOM',
      }),
    );
    analyticHelper.challengePhoto();
  };

  const handleCustomTextTypeTask = () => {
    dispatch(
      modalActions.openInputModal({
        title: 'Enter the answer',
        description: 'Use 1 to 15 symbols (latin letters, digits or umlauts)',
        btnText: 'OK',
        inputName: 'challenge',
        taskId: task.id,
        onButtonClick: (answer: string) => {
          dispatch(
            challengeActions.submit({
              answer: answer,
              id: challengeId,
            }),
          );
          dispatch(
            challengeActions.sendAttachmentsForCheck({
              taskId: task.id,
              taskType: type === TaskTypes.CustomTextManual ? 'CUSTOM_TEXT_MANUAL' : 'CUSTOM_TEXT_AUTO',
              quest_id: challengeId,
              answer,
            }),
          );
          history.push(Routes.SuccessPage, {
            title: 'Success',
            description: 'Your answer is accepted',
            btnText: 'OK',
            animated: true,
          });
        },
      }),
    );
  };

  const handleTaskCardClick = () => {
    analyticHelper.onTaskClick(description);

    if (isBrandTypeTask(type)) {
      history.push(Routes.BrandsRoute);
    } else if (isBonusShopTypeTask(type)) {
      history.push(Routes.BonusShopRoute);
    } else if (isLotteryTypeTask(type)) {
      history.push(`${Routes.ChanceRoute}/${chanceId}`);
    } else if (isProfileTypeTask(type)) {
      history.push(Routes.ProfileRoute);
    } else if (isKnowledgeBankTypeTask(type)) {
      history.push(Routes.KnowledgeBankRoute);
    } else if (isSpecialQuizTypeTask(type) || isBrandQuizTypeTask(type)) {
      history.push(`${Routes.KnowledgeBankRoute}/2`);
    } else if (isQuestionOfTheDayTypeTask(type) || isQuickTipsTypeTask(type)) {
      history.push(Routes.MainRoute);
    } else if (isKBGamesWonNumberTask(type)) {
      history.push(`${Routes.KnowledgeBankRoute}/${category_id}`);
    } else if (isGameTypeTask(type)) {
      dispatch(knowledgeBankActions.getCurrentArticle({ categoryId: category_id, entityId: entity_id }));
    } else if (isSingleModeTypeTask(type)) {
      dispatch(singleModeActions.setSingleModeStarted());
      history.push(Routes.SingleMode);
    } else if (IsSalesTipsTypeTask(type)) {
      history.push(Routes.KnowledgeBankRoute + Routes.SalesTips);
    } else if (isCustomTextTypeTask(type)) {
      handleCustomTextTypeTask();
    } else if (isLinkToChatTypeTask(type)) {
      history.push(Routes.Notifications);
    }
  };

  return (
    <TaskCard
      onTaskClick={handleTaskCardClick}
      onCustomImageUploadTaskClick={handleCustomImageTypeTask}
      task={task}
      photoFrame={photoFrame}
      attachmentsButtonEnabled={attachmentsButtonEnabled}
    />
  );
};
