import { isMybrandAZ, isTabaterraAZ } from './appTypeHelper';

const MYBRAND_AZ_PROMO_CODE_REGEXP = /TB[A-Z0-9]{6}/g;

export const findCodeInDetectionResponse = (detectionResponse: any) => {
  const textAnnotations = detectionResponse?.responses[0]?.textAnnotations;

  if (!textAnnotations) {
    return null;
  }

  for (let i = 1; i < textAnnotations.length; i++) {
    const text = textAnnotations[i].description as string;

    if (isTabaterraAZ() && text.match(MYBRAND_AZ_PROMO_CODE_REGEXP)) {
      return text;
    }

    if (isMybrandAZ() && textAnnotations[i - 1]?.description === '&') {
      return text;
    }
  }

  return null;
};
