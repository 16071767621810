import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { getUserBalanceSelector, getUserPhoneNumber } from '../../../redux/profile/profileSelectors';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { Routes } from '../../../const';
import { Balance } from '../../../components/common/Balance';
import { Header } from '../../../components/common/Header';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { Button } from '../../../components/common/Button';
import { CashoutHistory } from '../cashoutHistory/cashoutHistory';
import { isIPhoneWithHomeBar } from '../../../helpers/checkIsIphoneX';
import { checkUserRole } from '../../../helpers/checkUserRole';
import { UserRoles } from '../../../enums';
import M10Background from '../../../images/m10_backgroundImage.svg';
import { m10PaymentActions } from '../../../redux/m10Payment/m10Slice';
import { isPayoutSuccess, m10ExchangeRate, m10FetchingState, m10History } from '../../../redux/m10Payment/m10Selectors';
import { IM10Payout } from '../../../redux/m10Payment/m10Types';
import './moneyForTheCard.scss';

export const M10MainPage = () => {
  const { formatMessage } = useInternationalization();
  const coins = useSelector(getUserBalanceSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const phoneNumber = useSelector(getUserPhoneNumber);
  const transfersHistory = useSelector(m10History);
  const successfullPayout = useSelector(isPayoutSuccess);
  const exchangeRate = useSelector(m10ExchangeRate);
  const isFetching = useSelector(m10FetchingState);

  const rate = 1 / exchangeRate;
  const isModalShown = checkUserRole(UserRoles.BA, UserRoles.JTI_TEAM, UserRoles.RSM);

  const handlePreviousStep = () => {
    history.push(Routes.BonusShopRoute);
  };

  const onTransferClick = () => {
    if (isModalShown) {
      dispatch(
        modalActions.openPromptModal({
          description: 'Not available for JTI representatives.',
          btnText: 'OK',
          onButtonClick: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    } else {
      dispatch(
        modalActions.openCashoutModal({
          title: 'm10.payout_modal_title',
          btnText: 'm10.continue',
          symbol: '₼',
          moneyLimit: 60,
          rate,
          step: exchangeRate,
          makeTransfer: (params: IM10Payout) => {
            dispatch(m10PaymentActions.initPayout(params));
            dispatch(modalActions.closeModal());
          },
          onClose: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    }
  };
  useEffect(() => {
    dispatch(m10PaymentActions.initGetHistory());
    dispatch(m10PaymentActions.initGetExchange());
  }, [successfullPayout]);

  return (
    <div className="relative-container">
      <div className="money-for-the-card-page">
        <Header goBackCallback={handlePreviousStep} hasBackButton customClass="money-for-the-card-page__header">
          {formatMessage('m10.title')}
        </Header>
        <Balance fixed={undefined} activePage={undefined} />
        <div className="money-for-the-card-page__content">
          <div className="money-for-the-card-page__content-image-background-color">
            <div
              className="money-for-the-card-page__content-image-container"
              style={{
                background: `url(${M10Background}) no-repeat right`,
              }}
            >
              <div className="money-for-the-card-page__content-image-container-wallet-container">
                <p className="money-for-the-card-page__content-image-container-wallet-container-caption">
                  {formatMessage('m10.phone')}
                </p>
                <p className="money-for-the-card-page__content-image-container-wallet-container-wallet">
                  {phoneNumber}
                </p>
              </div>
            </div>
          </div>
          <CashoutHistory symbol="₼" rate={rate} specificTransfers={transfersHistory} />
        </div>
        <div
          style={{ bottom: isIPhoneWithHomeBar() ? '49px' : '15px' }}
          className="money-for-the-card-page__content-btn-container"
        >
          <Button
            disabled={isFetching}
            customClass={'money-for-the-card-page__content-btn-container-button'}
            onClick={onTransferClick}
          >
            {formatMessage('m10.transfer')}
          </Button>
        </div>
        <DisclaimerFooter withoutNavigation />
      </div>
    </div>
  );
};
