import { TaskTypes } from './challengeTypes';

export const BrandTypeTasks = [
  TaskTypes.PlayedQuizzesNumber,
  TaskTypes.QuizzesOnTopicNumber,
  TaskTypes.WonQuizzesNumber,
];

export const ProfileTypeTasks = [TaskTypes.KudosNumber];

export const BonusShopTypeTasks = [TaskTypes.PurchasedGiftsNumber, TaskTypes.SpentCoinsNumber];

export const LotteryTypeTasks = [TaskTypes.TakenPartLotteriesNumber, TaskTypes.Lottery];

export const KnowledgeBankTypeTask = [TaskTypes.ReadArticlesNumber];

export const SpecialQuizTypeTask = [TaskTypes.SpecialQuizzesNumber, TaskTypes.SpecialQuiz];

export const BrandQuizTypeTasks = [TaskTypes.MultibrandQuizzesNumber];

export const QuestionOfTheDayTypeTasks = [
  TaskTypes.DailyQuestionsPlayedNumber,
  TaskTypes.DailyQuestionsWonNumber,
  TaskTypes.WonDailyQuestion,
  TaskTypes.DailyQuestion,
];

export const GamesTypeTasks = [TaskTypes.WonKnowledgeBaseEntity, TaskTypes.KnowledgeBaseEntity];

export const SingleModeTypeTasks = [TaskTypes.SingleModePlayedInRowNumber, TaskTypes.SingleModePlayedNumber];

export const SalesTipsTypeTasks = [TaskTypes.SalesTips];

export const CustomTextTypeTask = [TaskTypes.CustomTextManual, TaskTypes.CustomTextAuto];

export const LinkToChatTypeTask = [TaskTypes.LinkToChat];

export const QuickTipsTypeTask = [TaskTypes.NumberOfPlayedQuickTips, TaskTypes.NumberOfWonQuickTips];
