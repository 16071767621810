import Holidays from 'date-holidays';
import { TestFunction } from 'yup';

import mybrandCitiesData from '../const/mybrand-az/shippingData.json';
import { IOrderDetails } from '../types/IOrderDetails';
import { MIN_DELIVERY_TERM_IN_DAYS } from '../const';
import { IOrderCityData } from '../types/IOrderCityData';

const holidays = new Holidays('AZ');

export const validateWorkingDay: TestFunction<string | undefined> = (value) => {
  if (!value) {
    return false;
  }

  const date = new Date(value);
  const day = date.getDay();

  return day !== 0 && day !== 6 && !holidays.isHoliday(date);
};

export const validateMinWorkingDaysCount: TestFunction<string | undefined> = (value) => {
  if (!value) {
    return false;
  }

  const dateFrom = new Date();
  const dateTo = new Date(value);

  return checkRequiredBusinessDaysCount(dateFrom, dateTo, MIN_DELIVERY_TERM_IN_DAYS);
};

export const formatOrderDetailsAddress = (orderDetails: IOrderDetails) => {
  const { street, house, apartment, zip, city } = orderDetails;

  if (!city) {
    return null;
  }

  return `${street} ${house}/${apartment}, ${zip} ${city}`;
};

export const formatOrderDetailsDeliveryDateTime = (
  orderDetails: IOrderDetails,
  t: (id: any, values: any) => string,
) => {
  const { delivery_date, delivery_time } = orderDetails;

  if (!delivery_date || !delivery_time) {
    return null;
  }

  return `${delivery_date} ${t('bonus_shop.order_details.time_slot', {
    timeFrom: delivery_time[0],
    timeTo: delivery_time[1],
  })}`;
};

export const checkRequiredBusinessDaysCount = (dateFrom: Date, dateTo: Date, requiredBusinessDays: number) => {
  let workingDays = 0;

  const currentDate = new Date(dateFrom.getTime());

  while (currentDate <= dateTo) {
    const day = currentDate.getDay();
    if (day !== 0 && day !== 6 && !holidays.isHoliday(currentDate)) {
      workingDays++;
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return workingDays >= MIN_DELIVERY_TERM_IN_DAYS;
};

export const getCityDataByName = (city: string) => {
  return mybrandCitiesData.find(({ city_name }) => city_name === city);
};

export const sortCityData = () => {
  const primaryCities: IOrderCityData[] = [];

  const citiesWithDelivery = mybrandCitiesData.filter(({ delivery }) => delivery).map(({ city_name }) => city_name);

  const restCities = mybrandCitiesData
    .filter((city) => {
      if (!citiesWithDelivery.includes(city.city_name)) {
        return true;
      }

      primaryCities.push(city);
    })
    .sort((cityA, cityB) => cityA.city_name.localeCompare(cityB.city_name));

  return [...primaryCities, ...restCities];
};
